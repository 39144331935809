export function getType(opt) {
    return axios.post('/product/type/get/', opt);
}

export function updateType(opt) {
    return axios.post('/product/type/update/', opt);
}

export function getItemList(opt) {
    return axios.post('/product/item/get/', opt);
}

export function addItemList(opt) {
    return axios.post('/product/item/insert/', opt);
}

export function getItemDetail(opt) {
    return axios.post('/product/detail/get/', opt);
}

export function updateItemDetail(opt) {
    return axios.post('/product/detail/update/', opt);
}

export function delItem(opt) {
    return axios.post('/product/item/delete/', opt);
}
