<template>
  <div>
    <el-card class="box-card" shadow="always">
      <el-table
          :data="honorList"
          :fit="true"
          max-height="800"
          style="width: 100%">
        <el-table-column
            type="index"
            align="center"
            label="序号">
        </el-table-column>
        <el-table-column
            label="系列"
            prop="series"
            align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.seriesId == 1">9N</span>
            <span v-else-if="scope.row.seriesId == 2">7N</span>
            <span v-else-if="scope.row.seriesId == 3">高压</span>
            <span v-else-if="scope.row.seriesId == 4">GC</span>
			<span v-else-if="scope.row.seriesId == 9">过滤器系列</span>
            <span v-else-if="scope.row.seriesId == 5">9N</span>
            <span v-else-if="scope.row.seriesId == 6">7N</span>
            <span v-else-if="scope.row.seriesId == 7">HP/UP</span>
            <span v-else-if="scope.row.seriesId == 8">GC</span>
			<span v-else-if="scope.row.seriesId == 10">Filter series</span>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            label="产品图">
          <template slot-scope="scope">
            <img class="table-img" :src="scope.row.img">
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            prop="name"
            label="产品名称">
        </el-table-column>
        <el-table-column
            align="center"
            prop="intro"
            label="简单描述">
          <template slot-scope="scope">
            <span>{{scope.row.intro}}</span>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            prop="weight"
            label="权重">
        </el-table-column>
        <el-table-column
            prop="type"
            align="center"
            label="操作">
          <template slot="header" slot-scope="scope">
            <el-button size="medium" type="primary" @click="addHonor">新增</el-button>
          </template>
          <template slot-scope="scope">
            <el-link :underline="false" type="primary" @click="$router.push('/product/detail/'+scope.row.id)">完善信息</el-link>
            <el-link :underline="false" type="danger" @click="deleteItem(scope.row.id)">删除</el-link>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-dialog
        title="新增"
        :visible.sync="addDialogVisible"
        width="50%">
      <el-form ref="form" :model="addForm" label-width="120px">
        <el-form-item label="所属系列">
          <el-radio-group v-model="addForm.type" size="medium">
            <el-radio-button label="1">9N</el-radio-button>
            <el-radio-button label="2">7N</el-radio-button>
            <el-radio-button label="3">高压</el-radio-button>
            <el-radio-button label="4">GC</el-radio-button>
			<el-radio-button label="9">过滤器系列</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="产品名">
          <el-input type="text" v-model="addForm.name"></el-input>
        </el-form-item>
        <el-form-item label="图片">
          <img-upload :url="addForm.url" @change="urlChange"></img-upload>
        </el-form-item>
        <el-form-item label="描述">
          <el-input type="textarea" v-model="addForm.desc" :autosize="{ minRows: 6, maxRows: 20}"></el-input>
        </el-form-item>
        <el-form-item label="权重">
          <el-input type="number" v-model="addForm.weight"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button @click="addNew">确 认</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import tinymce from 'tinymce/tinymce';
import Editor from '@tinymce/tinymce-vue';
import 'tinymce/themes/silver'
import 'tinymce/icons/default'
import {addItemList, getItemList, delItem} from "@/plugins/api/product";
import ImgUpload from "@/components/ImgUpload";

export default {
  name: 'Center',
  components: {
    ImgUpload,
    'editor': Editor
  },
  data() {
    return {
      init: {
        language_url: '/static/tinymce/lang/zh_CN.js',
        language: 'zh_CN',
        skin_url: '/static/tinymce/skin/oxide',
        height: 300,
        branding: false,
        content_style: "p {margin: 0}",
        menubar: false,
        toolbar: 'undo redo | bold fontsizeselect forecolor',
      },
      honorList: [
        {
          id: 1,
          url: 'http://www.simpuretech.com/static/images/product/center/9NP.png',
          weight: 10,
          series: '9N系列',
          title: '9NP',
          desc: 'POU纯化器',
        },
        {
          id: 1,
          url: 'http://www.simpuretech.com/static/images/product/center/P9.png',
          weight: 10,
          series: '9N系列',
          title: 'P9',
          desc: '国产POU纯化器',
        },
        {
          id: 1,
          url: 'http://dev.simpuretech.com/static/images/product/center/9NS-IM.png',
          weight: 10,
          series: '9N系列',
          title: '9NS-IM',
          desc: '可去除CH4的\n' +
              '纯化系统\n',
        },
        {
          id: 1,
          url: 'http://dev.simpuretech.com/static/images/product/center/GCP.png',
          weight: 10,
          series: 'GC系列',
          title: 'GC Pure',
          desc: '载气纯化器',
        },
      ],
      previewInfo: {},
      dialogVisible: false,
      addDialogVisible: false,
      addForm: {
        type: 1,
        name: '',
        url: '',
        weight: '10',
        desc: '',
      },
      fileList: []
    }
  },
  methods: {
    async deleteItem(id) {
      await delItem({id})
      this.getItem()
    },
    preview(row) {
      this.previewInfo = row;
      this.dialogVisible = true;
    },
    addHonor() {
      this.addDialogVisible = true
    },
    urlChange(url) {
      this.addForm.url = url
    },
    async addNew() {
      await addItemList({
        name: this.addForm.name,
        img: this.addForm.url,
        intro: this.addForm.desc,
        weight: this.addForm.weight,
        series_id: Number.parseInt(this.addForm.type) + (this.$store.state.lang == 'zh' ? 0 : 4),
      })
      this.addForm = {
        type: 1,
        name: '',
        url: '',
        weight: '10',
        desc: '',
      };
      this.addDialogVisible = false;
      this.getItem()
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    async getItem() {
      this.honorList = await getItemList()
    }
  },
  created() {
    this.getItem()
  }
}
</script>

<style scoped>
.table-img {
  width: auto;
  height: auto;
  max-width: 200px;
  max-height: 200px;
}
.preview-img {
  width: 100%;
  height: auto;
  max-height: 500px;
  object-fit: contain;
}
</style>
